import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql, useStaticQuery } from "gatsby"
import Container from 'react-bootstrap/Container'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Release from "../components/Release"
import Footer from '../components/footer'
import { bdstatistics, apiURL, mqStatistics, check } from '../components/census'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

export default () => {
  const [active, setActive] = React.useState('v4')

  const reposData = useStaticQuery(graphql`
      {
        allStrapiRepos(sort: {fields: group_name, order: ASC}) {
          nodes {
            releases {
              created_at
              published_at
              tag_name
              id
              updated_at
              description
              assets {
                id
                name
                url
              }
            }
            group_name
            strapiId
            repo_name
            description
            id
          }
        }
      }
  `)
  const data = reposData.allStrapiRepos?.nodes || [];

  const isSemanticVersion = (tag) => {
    const regex = /^v(3|4)\.\d+\.\d+$/;
    return regex.test(tag);
  };

  const filteredReleases = data.reduce((acc, repo) => {
    const { releases, ...repoInfo } = repo;
    const filtered = releases.filter(release => isSemanticVersion(release.tag_name));
    filtered.forEach(release => {
      release.repo = repoInfo;
    });
    acc.push(...filtered);
    return acc;
  }, []);

  const groupedReleases = filteredReleases.reduce((acc, release) => {
    const version = release.tag_name.startsWith('v3') ? 'v3' : 'v4';
    if (!acc[version]) {
      acc[version] = [];
    }
    acc[version].push(release);
    return acc;
  }, {});

  // 根据 published_at 进行降序排列，并切片
  const sortAndSliceReleases = (releases, limit) => {
    return releases
      .sort((a, b) => new Date(b.published_at) - new Date(a.published_at))
      .slice(0, limit);
  };

  groupedReleases.v4 = Array.isArray(groupedReleases.v4) ? sortAndSliceReleases(groupedReleases.v4, 150) : [];
  groupedReleases.v3 = Array.isArray(groupedReleases.v3) ? sortAndSliceReleases(groupedReleases.v3, 150) : [];


  React.useEffect(() => {
    bdstatistics()
    mqStatistics()
    // fetch(`${apiURL}/releases/count`, { headers: { 'content-type': 'application/json' } })
    //   .then(res => {
    //     if (res.status == 200) return res.json()
    //   })
    //   .then(json => setListData({ ...listData, total: json }) )
    // fetch(`${apiURL}/releases?_start=${1}&_limit=${150}&_publicationState=live&_sort=published_at:DESC`, {
    //   headers: { 'content-type': 'application/json' }
    // })
    //   .then((res) => { if (res.status == 200) return res.json() })
    //   .then(json => {
    //     setData(json)
    //   })
    //   .catch(err => console.log(err))
  }, [active])

  let arr = [{ title: '4.0', eventKey: 'v4' }, { title: '3.0', eventKey: 'v3' }]

  const onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize);
  };

  return (
    <>
      <div className="home-bg" style={{ background: `url('')`, height: '7.5rem' }}>
        <Layout><SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" /></Layout>
      </div>
      <Container id="releasesRepo" className="content-box">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="repos-title-box" style={{ flex: 1, textAlign: 'left' }}>
            <span className="title-line"></span>
            <span className="title-repos">版本列表</span>
          </div>
        </div>
        <div className="tab-box-bb" style={{ minHeight: check() ? 150 : 200 }}>
          <Tabs defaultActiveKey={active} onSelect={e => { setActive(e) }}>
            {
              arr.map(item => {
                return <Tab eventKey={item?.eventKey} title={item?.title}>
                  {
                    groupedReleases[item.eventKey]?.length ? groupedReleases[item.eventKey].map(r => <Release release={r} showRepo={true} />) : null
                  }
                </Tab>
              })
            }
          </Tabs>
        </div>
      </Container>
      <Footer style={{ marginTop: '7.5rem' }}></Footer>
    </>
  )
}